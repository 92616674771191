<template>
  <div v-if="!loading" class="media-poster-wrapper" :class="{ 'is-partner': isPartner }">
    <!-- MEDIA POSTER -->
    <div class="media-poster">
      <!-- POSTER SIZER -->
      <img width="350" src="./assets/img/poster-sizer--350x525.svg" alt="Media Poster" />

      <!-- DIRTY SELF PROMOTER -->
      <img
        v-if="isSelfPromoter"
        src="./assets/img/dirty-self-promoter@2x.png"
        class="dirty-self-promoter-stamp"
        alt="Dirty Self Promoter"
      />

      <!-- POSTER INNER -->
      <div class="media-poster-inner" ref="media-poster-inner">
        <!-- POSTER HEADER -->
        <div class="media-poster-header">
          <!-- TITLE -->
          <h1 class="media-poster-header-title">
            <span class="text fitty" ref="media-poster-header-title">
              {{ mediaObj.display_name }}
            </span>
          </h1>

          <!-- HERO -->
          <div class="media-poster-header-hero">
            <span
              class="media-poster-header-hero-bg"
              :style="{ backgroundImage: 'url(' + mediaObj.profile_image_url + ')' }"
            >
            </span>
          </div>
        </div>

        <!-- POSTER BODY -->
        <div class="media-poster-body">
          <!-- POSTER BODY TEXT -->
          <div class="media-poster-body-text">
            <!-- BATTING AVG -->
            <div class="d-flex">
              <span class="">Batting Avg</span>
              <span class="dotted-line"><span class="dots"></span></span>

              <ICountUp
                v-if="battingAverage"
                :delay="300"
                :endVal="battingAverage"
                :options="{ prefix: battingAveragePrefix }"
                class="text-danger ml-auto"
              />
            </div>

            <!-- THROWS -->
            <div class="d-flex">
              <span>Throws</span>
              <span class="dotted-line"><span class="dots"></span></span>
              <span class="ml-auto text-danger">
                {{ getRandomVal(throws, mediaObj.id) }}
              </span>
            </div>

            <!-- FANS -->
            <div class="d-flex">
              <span>Fans</span>
              <span class="dotted-line"><span class="dots"></span></span>

              <ICountUp
                v-if="totalFollows"
                :delay="300"
                :endVal="totalFollows"
                class="text-danger ml-auto"
              />
            </div>
          </div>

          <!-- POSTER BODY AUTOGRAPH -->
          <div class="media-poster-body-autograph animated bounceIn">
            <span class="text">
              {{ getRandomVal(autographs, mediaObj.id) }}
            </span>
          </div>
        </div>

        <!-- POSTER FOOTER -->
        <div class="media-poster-footer">
          <!-- FOOTER IMAGE -->
          <img
            class="media-poster-footer-image"
            width="113"
            :src="getRandomVal(baseballPlayerGraphics, mediaObj.id)"
            alt="Baseball Player"
          />

          <!-- FOOTER TEXT -->
          <div class="media-poster-footer-text">
            <span class="text-1">LVL</span>
            <span class="text-2">{{ level }}</span>
          </div>
        </div>
      </div>

      <!-- POSTER ATTRIBUTION -->
      <slot />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import ICountUp from "vue-countup-v2";
  import twitchConfig from "@/config/twitch.js";
  import fitty from "fitty";
  // import ColorThief from 'color-thief';

  /*
		TODO:
		* v.2 - card flip
		* colours?
		*
	*/
  export default {
    components: {
      ICountUp,
    },
    props: {
      author: {
        type: String,
        required: false,
      },
      dataObject: {
        type: Object,
        required: true,
      },
      path: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loading: true,
        mediaObj: null,
        autographs: [
          "Believe in your dreams! xox",
          "I'm trapped in a card!",
          "Say nay to drugs! xox",
          "Slam dunk!",
          "Stay in school",
          "Stay in school xox",
          "Let's get crazy.",
          "Be true to urself xx",
          "Touchdown!",
          "Touchdown! xox",
          "Touch the sky!",
        ],
        baseballPlayerGraphics: [
          require("./assets/img/baseball-player-1@2x.png"),
          require("./assets/img/baseball-player-2@2x.png"),
        ],
        throws: ["RIGHT", "RIGHT", "RIGHT", "LEFT"],
        totalFollows: 0,
        totalViews: Math.floor(Math.random() * 50),
      };
    },
    computed: {
      // Loose level algorithm.
      level() {
        // const viewsCount = this.totalViews; //this.mediaObj.view_count.toString().length;
        // const fans = this.totalFollows;
        // const views = this.mediaObj.view_count;
        // const multiplier = ((fans/views) * 0.01).toFixed(2);
        // const multiplier = (fans > 200 && 1) || (fans < 20 && -2) || (fans < 50 && -1);
        // return (viewsCount + multiplier) * 10;

        const maxLevel = 60;
        const maxTotalFollows = 400000;
        const level = Math.floor((this.totalFollows / maxTotalFollows) * maxLevel);
        const randomLevel = Math.floor(Math.random() * (level + 90));
        return randomLevel;
      },
      // totalViews() {
      //return this.mediaObj.view_count || 0; // view_count was deprecated by Twitch api
      // },
      // Batting Avg uses last .3 decimal places.
      battingAverage() {
        // const ba = (this.totalFollows / this.totalViews)
        //   .toFixed(3)
        //   .toString()
        //   .slice(2);
        // const final = ba > 0 ? Number(ba) : 0;
        // return Number(final);
        return Math.floor(Math.random() * 366);
      },
      // Used to 'CountUp'.
      battingAveragePrefix() {
        const ba = this.battingAverage;
        let prefix;
        if (ba.toString().length === 1) prefix = ".00";
        else if (ba.toString().length === 2) prefix = ".0";
        else prefix = ".";
        return prefix;
      },
      isPartner() {
        return this.mediaObj.broadcaster_type === "partner";
      },
      isSelfPromoter() {
        return this.dataObject.author.toLowerCase() === this.mediaObj.display_name.toLowerCase();
      },
    },
    watch: {
      loading(newVal) {
        if (!newVal) {
          this.$nextTick(() => {
            const boundary = this.$refs["media-poster-inner"].offsetWidth;
            const titleEl = this.$refs["media-poster-header-title"];
            const textWidth = titleEl.offsetWidth;

            // Fit title if exceeding boundaries of inner frame.
            if (textWidth > boundary) fitty(titleEl);

            this.$playSound("baseball-organ", { interrupt: true, volume: 0.8 }); // PLAY SOUND
          });
        }
      },
    },
    mounted() {
      // INIT
      this.fetchPosterData(this.path, this.type);
    },
    methods: {
      //-----------------------------------------------------------------
      // FETCH POSTER DATA
      //-----------------------------------------------------------------

      fetchPosterData(username) {
        this.$store
          .dispatch("twitch/fetchUser", username)
          .then((twitchUser) => {
            if (twitchUser?.id) {
              // Failures won't have ids.

              // Store data
              this.mediaObj = twitchUser;

              const path = twitchUser.profile_image_url;

              // Prepare main image
              const img = new Image();
              img.crossOrigin = "anonymous"; // For colorThief.
              img.src = path;

              img.onload = () => {
                this.loading = false;
                console.log("Success!", this.mediaObj);
              };

              // Fetch data follows.
              this.$store
                .dispatch("twitch/fetchChannelFollowers", twitchUser.id)
                .then((data) => {
                  console.log({ data });
                  this.totalFollows = parseInt(data.total);
                  this.saySuccess();
                })
                .catch((err) => {
                  this.totalFollows = 666;
                  console.warn("Oops! There was an error with follow fetching:", err);
                });
            } else {
              // console.warn('Oops! No user was found with that name.', this.author);
              // REFUND USER POINTS
              // this.$store.dispatch('chatbot/refundUserPoints', this.dataObject);
              // SAY MESSAGE - "FAIL"
              // this.$store.dispatch('chatbot/sayMessage', {
              // 	color: 'Red',
              // 	message: `@${this.author} There's no streamer by that name. ❌`,
              // });
            }
          })
          .catch((error) => {
            console.error("Oops! Poster didn't load.", error);
          });
      },

      //-----------------------------------------------------------------
      // SAY SUCCESS
      //-----------------------------------------------------------------

      saySuccess() {
        // TODO: change to global broadcaster
        if (
          this.author.toLowerCase() === "themitchinghour" ||
          this.author.toLowerCase() === "system"
        ) {
          // SAY MESSAGE -- as Mitch
          // this.$store.dispatch('chatbot/sayMessage', {
          // 	color: 'SpringGreen',
          // 	message: "themit10Head Go and give this streamer a cheeky follow: https://twitch.tv/${arg} <3",
          // 	arg: this.path
          // });
        } else {
          // TODO: REINSTATE THIS WHEN LOYALTY DONE
          // SAY MESSAGE -- as Random
          // this.$store.dispatch('chatbot/sayMessage', {
          // 	color: 'Blue',
          // 	message: "imGlitch ${user} thinks you should go and check out this streamer: https://twitch.tv/${arg}  👍",
          // 	arg: this.path,
          // 	user: this.author
          // });
        }
      },

      //-----------------------------------------------------------------
      // COLOR THIEF
      //-----------------------------------------------------------------

      /*
			const colorThief = new ColorThief()
			const rgb = colorThief.getColor(img);
			const palette = colorThief.getPalette(img, 3);
			console.log('palette', palette);
			const color = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
			console.log('color', color)
			this.colorPrimary = '#284998'; //color;
			*/

      // stripEmoji(text) {
      // 	return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
      // }

      //-----------------------------------------------------------------
      // GET RANDOM VAL
      //-----------------------------------------------------------------

      getRandomVal(targetArr, seedStr) {
        // https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed
        function shuffle(array, seed) {
          var m = array.length,
            t,
            i;
          while (m) {
            i = Math.floor(random(seed) * m--);
            t = array[m];
            array[m] = array[i];
            array[i] = t;
            ++seed;
          }
          return array;
        }

        function random(seed) {
          var x = Math.sin(seed++) * 10000;
          return x - Math.floor(x);
        }

        // Strip all letters to create numeric seed.
        const seed = seedStr.toString().replace(/\D/g, "");

        // Create new array shuffled.
        // https://stackoverflow.com/questions/1862130/strip-all-non-numeric-characters-from-string-in-javascript
        return shuffle(targetArr.slice(), seed)[0];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap");

  //-----------------------------------------------------------------
  // MEDIA POSTER WRAPPER - HAS VUE TRANSITION
  //-----------------------------------------------------------------

  .media-poster-wrapper {
    position: absolute;
    top: rem-calc(100); // [LOCKED]
    left: rem-calc(290); // [LOCKED]
    // border: 2px dashed red;
  }

  //-----------------------------------------------------------------
  // MEDIA POSTER
  //-----------------------------------------------------------------

  .media-poster {
    position: relative;
    padding: rem-calc(8); // [LOCKED] - extends card out
    background-color: #f6eac7; //#e1dfdd;
    // border: 1px dashed orange;
    box-shadow: 0 10px 50px 20px rgba(black, 0.8);
    border-radius: rem-calc(20);
    // transform: perspective(400px) rotateY(10deg);
    animation: float 3s infinite linear;

    @keyframes float {
      from {
        transform: scale(1.1) rotate(0deg) translate3d(3px, 0, 0) rotate(0deg) perspective(400px)
          rotateY(10deg);
      }
      to {
        transform: scale(1.1) rotate(360deg) translate3d(3px, 0, 0) rotate(-360deg)
          perspective(400px) rotateY(10deg);
      }
    }

    // WORN TEXTURE
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: rem-calc(20);
      background: url("./assets/img/texture-ivory@2x.png") no-repeat center center;
      background-size: cover;
      mix-blend-mode: multiply;
      z-index: 1;
    }
  }

  //-----------------------------------------------------------------
  // DIRTY SELF PROMOTER STAMP
  //-----------------------------------------------------------------

  .dirty-self-promoter-stamp {
    position: absolute;
    top: rem-calc(130);
    left: rem-calc(10);
    width: rem-calc(150);
    transform: rotate(-10deg);
    z-index: 2;
  }

  //-----------------------------------------------------------------
  // MEDIA POSTER INNER (THE FRAME)
  //-----------------------------------------------------------------

  .media-poster-inner {
    display: flex;
    flex-direction: column;
    position: absolute;
    $pad: rem-calc(18);
    top: $pad;
    left: $pad;
    width: calc(100% - #{$pad * 2});
    height: calc(100% - #{$pad * 2});
    // border: 4px solid #264691;
    border-radius: rem-calc(15);
    box-shadow: 0 2px 5px 3px rgba(black, 0.2);
    background-color: #284998;
    overflow: hidden;

    // WORN TEXTURE
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("./assets/img/texture-worn@2x.png") no-repeat center center;
      background-size: contain;
      z-index: 1;
    }
  }

  //-----------------------------------------------------------------
  // POSTER HEADER
  //-----------------------------------------------------------------

  .media-poster-header {
    padding: rem-calc(0 4); // experimental
    height: rem-calc(200);
    // background: #e1e1e1; // optional
    z-index: 1; // [LOCKED]

    // STARS
    &:before {
      content: "★★★";
      position: absolute;
      top: rem-calc(10);
      left: rem-calc(15);
      color: white;
      font-size: rem-calc(26);
      letter-spacing: 6px;
    }

    // TITLE
    .media-poster-header-title {
      position: relative; // [LOCKED]
      width: 100%;
      padding: rem-calc(8 0);
      font-family: "Front Page Neue";
      font-size: rem-calc(54);
      text-transform: uppercase;

      // word-break: break-all;
      // hyphens: auto;
      // line-height: 1;

      text-align: center;
      color: white;
      transform: skewY(-10deg);
      transform-origin: top right;
      z-index: 1; // [LOCKED]

      // visibility: hidden;

      // https://stackoverflow.com/questions/26634201/add-stroke-around-text-on-the-outside-with-css
      text-shadow: -3px -3px 0 #e21d25, 0 -3px 0 #e21d25, 3px -3px 0 #e21d25, 3px 0 0 #e21d25,
        3px 3px 0 #e21d25, 0 3px 0 #e21d25, -3px 3px 0 #e21d25, -3px 0 0 #e21d25;

      // animation: floater 3s infinite linear;

      // @keyframes floater {
      // 	from { transform: skewY(-10deg) rotate(0deg)   translate3d(-1px, 0, 0) rotate(0deg)   }
      // 	to   { transform: skewY(-10deg) rotate(360deg) translate3d(-1px, 0, 0) rotate(-360deg) }
      // }
    }
  }

  // PARTNER
  .is-partner .media-poster-header:after {
    content: "";
    position: absolute;
    top: rem-calc(471); // 17
    left: rem-calc(232); // 112
    width: rem-calc(16);
    height: rem-calc(16);
    background: url("./assets/img/icon-twitch-partner--white@2x.png") no-repeat;
    background-size: contain;
    z-index: 1;
  }

  //-----------------------------------------------------------------
  // POSTER HEADER HERO
  //-----------------------------------------------------------------

  .media-poster-header-hero {
    position: absolute;
    top: rem-calc(70);
    left: 0;
    width: 100%;
    height: rem-calc(160);
    background-color: grey; // while loading
    overflow: hidden; // [LOCKED]
    transform: skewY(-10deg);

    // RED STRIP
    &:after {
      content: "";
      position: absolute;
      top: rem-calc(151);
      width: 100%;
      height: rem-calc(100);
      background-color: #e21d25;
      transform: skewY(3deg);
    }

    // HERO BG
    .media-poster-header-hero-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: rem-calc(300);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      filter: grayscale(100%); // [LOCKED]
      transform: skewY(10deg);
      transform-origin: top right;
    }
  }

  //-----------------------------------------------------------------
  // POSTER BODY
  //-----------------------------------------------------------------

  .media-poster-body {
    display: flex;
    height: rem-calc(224);
    padding: rem-calc(25 20 20 20);
    background: white;
    color: #222;
    overflow: hidden; // [LOCKED]
    transform: skewY(-10deg);
    transform-origin: top right;

    // GREY STRIP
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: rem-calc(215);
      width: 100%;
      height: rem-calc(100);
      background-color: rgba(black, 0.1);
      transform: skewY(-3deg);
      z-index: -2;
    }

    // TEXT
    .media-poster-body-text {
      width: rem-calc(230);
      margin: auto;
      margin-top: rem-calc(10);
      line-height: 1.2;
      font-family: "Avenir Next Condensed";
      font-size: rem-calc(22);
      font-weight: 600;
      text-transform: uppercase;

      .dotted-line {
        position: relative;
        flex-grow: 1;
        // background: aqua;
        overflow: hidden;
        margin: rem-calc(0 3.8);

        .dots:after {
          content: ".....................................";
          position: absolute;
          bottom: 0;
        }
      }
    }

    // AUTOGRAPH
    .media-poster-body-autograph {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: 0;
      width: rem-calc(220);
      height: rem-calc(100);
      padding: rem-calc(15 30 15 15);
      // border: 1px solid red;
      font-family: "YellowTail";
      font-size: rem-calc(34);
      font-weight: 500;
      text-align: center;
      line-height: 0.8;

      animation-delay: 1.6s;

      // INK SPILL
      .text:after {
        content: "";
        position: absolute;
        top: rem-calc(4);
        right: rem-calc(-5);
        width: rem-calc(50);
        height: rem-calc(50);
        background: url("./assets/img/ink-spill@2x.png") no-repeat center center;
        background-size: 70%;
        opacity: 0.8;
      }
    }
  }

  //-----------------------------------------------------------------
  // POSTER FOOTER
  //-----------------------------------------------------------------

  .media-poster-footer {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    // IMAGE
    .media-poster-footer-image {
      margin-left: rem-calc(10);
    }

    // TEXT
    .media-poster-footer-text {
      margin: rem-calc(20 15 10 20);
      margin-left: auto;
      font-family: "Front Page Neue";
      font-weight: 300;
      color: white;
      transform: scaleY(1.3);
      transform-origin: bottom;
      line-height: 1;

      .text-1 {
        font-size: rem-calc(24);
        margin-right: rem-calc(5);
      }

      .text-2 {
        font-size: rem-calc(32);
      }
    }
  }
</style>
